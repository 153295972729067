import React from "react";

import Paragraph from "../ui/common/Paragraph";
import Section from "../ui/common/Section";

const items = [
  {
    title: "",
    text: (
      <Paragraph>
        <i>microPublication</i> journals adhere to the highest standards for
        publication ethics, transparency and data FAIRness. Specifically,
        *microPublication* Biology conforms to the Minimum Information Standards
        for scientific data reporting
        ([MIBBI](https://biosharing.org/collection/MIBBI)), follows the
        guidelines of the Committee on Publication Ethics
        ([COPE](https://publicationethics.org/)), adheres — as far as possible
        given the novel publication platform– with recommendations in the
        [Uniform Requirements for Manuscripts Submitted to Biomedical
        Journals](http://www.icmje.org/recommendations/) and follows the FAIR
        (findable, accessible, interoperable and reproducible) data principles
        ([Wilkinson et al., 2016](https://www.nature.com/articles/sdata201618)).{" "}
        <i>microPublication Biology</i> will use the deep curatorial expertise
        of these organizations to ensure that federal funding mandates for FAIR
        principles in data management are adhered to, aiding researchers in
        complying with their funding bodies in a trackable and persistent
        manner."
      </Paragraph>
    ),
  },

  {
    title: "Open Access License for microPublication Biology",
    text: (
      <Paragraph>
        Each <i>microPublication</i> is an open-access article distributed under
        the terms of the [Creative Commons Attribution
        License](https://creativecommons.org/licenses/by/4.0/).
      </Paragraph>
    ),
  },
  {
    title: "Conflicts of Interest",
    text: (
      <Paragraph>
        <i>microPublication</i> journals adhere to the Committee on Publication
        Ethics ([COPE](https://publicationethics.org/)) guidelines on conflicts
        of interest. Authors, reviewers and editors are required to declare any
        conflicts of interests that could be perceived as affecting the
        objectivity of presenting, reviewing or handling of the work.
      </Paragraph>
    ),
  },

  {
    title: "Authorship",
    text: (
      <>
        <Paragraph>
          <i>microPublication</i> journals follow the authorship guidelines of
          the International Committee of Medical Journal Editors
          ([ICMJE](http://www.icmje.org/recommendations/)). All authors share
          responsibility for the content of the *microPublication*. Authorship
          should be limited to those persons that performed the experiment, any
          analysis, and or write-up. Individuals who do not satisfy authorship
          guidelines but participated materially in the work, should be included
          in the acknowledgement section. Submitting authors are required to
          assign authorship contributions using the Contributor Roles Taxonomy
          ([CRediT](http://credit.niso.org)) to provide transparency to the
          contributions of researchers to scholarly published work, to enable
          discoverability, and to improve attribution, credit, and
          accountability.
        </Paragraph>

        <Paragraph>
          Individuals who do not satisfy authorship guidelines but participated
          materially in the work, should be included in the acknowledgement
          section.
        </Paragraph>

        <Paragraph>
          The Principal Investigator (PI) of the laboratory in which the work
          was performed may or may not be included at the discretion of the PI;
          however this PI must approve the submission of the article and provide
          the details of any funding source.
        </Paragraph>
        <Paragraph>
          Authors are required to accept the following conditions when
          submitting an article:
          <br />
          “I/we declare to the best of my/our knowledge that the experiment is
          reproducible; that the submission has been approved by all authors;
          that the submission has been approved by the laboratory’s Principal
          Investigator, and that the results have not been published elsewhere.*
          The author(s) declare no conflict of interest.”
        </Paragraph>

        <Paragraph>
          *Note: We do not consider publication as an academic thesis,
          electronic preprint, or abstract as a prior publication.
        </Paragraph>
      </>
    ),
  },
  {
    title: "Scientific Misconduct",
    text: (
      <>
        <Paragraph>
          If <i>microPublication</i> journals receive an allegation of possible
          research or publication misconduct, affected individuals will be
          contacted for a response. *microPublication* journals follow the
          [COPE](https://publicationethics.org/) (Committee on Publication
          Ethics) guidelines on scientific misconduct. *microPublication*
          journals reserve the right to (a) terminate the review process; (b)
          take the appropriate steps to correct the scientific record; and (c)
          contact employer, institution or funding agency/regulatory body to
          investigate. *microPublication* journals do not consider unintentional
          mistakes (e.g., incorrect author list, incorrect strain name, etc.) to
          be misconduct. In these cases, the change will be fixed and published
          as a Corrigendum.
        </Paragraph>

        <Paragraph>
          At the time of submission manuscripts are scanned with text plagiarism
          software. We will introduce image plagiarism checks in the future.
        </Paragraph>
      </>
    ),
  },
  {
    title: "Funding Agencies",
    text: (
      <Paragraph>
        The funding agency or source must be indicated in the appropriate
        submission field.
      </Paragraph>
    ),
  },
  {
    title: "Data and Reagents",
    text: (
      <>
        <Paragraph>
          It is the expectation that published data and reagents will be made
          freely available, in a timely manner, upon request. In addition, all
          data will be made available for reuse on public, open-access,
          community knowledgebases.
        </Paragraph>
        <Paragraph>
          In the case of a <i>microPublication</i> providing results that
          support the scientific validity of a commercial commodity (e.g.,
          engineered strain, apparatus, etc.), the article is indicated as a
          “Commodity validation”.
        </Paragraph>
      </>
    ),
  },
];

const PolicySection = (props) => {
  const { policyRef, isPage } = props;

  return (
    <Section
      items={items}
      title="Editorial Policies"
      sectionRef={policyRef}
      isPage={isPage}
    />
  );
};

export default PolicySection;
